<template>
  <div style="padding: 2rem;">
    <h1>Informations personnelles</h1>
    <div style="width: 300pt; margin: auto;">
      <div style="display: flex;">
        <div>
          <label>Nom<br/>
            <input type="text" placeholder="Mon nom" v-model="userData.nom">
          </label>
          <label>Prénom<br/>
            <input type="text" placeholder="Mon prénom" v-model="userData.prenom">
          </label>
          <label>N° d'apiculteur (NAPI)<br/>
              <input type="text" placeholder="Mon code NAPI" v-model="userData.napi">
          </label>

          <label>Votre adresse<br/>
            <input type="text" placeholder="N° et nom de rue" v-model="userData.adresse1" style="margin-bottom: 2px">
            <input type="text" placeholder="Infos supplémentaires" v-model="userData.adresse2" style="margin-bottom: 2px">
            <input type="text" placeholder="CP et Ville" v-model="userData.adresse3">
          </label>

          <label>Votre N° de téléphone<br/>
            <input type="text" placeholder="Votre téléphone" v-model="userData.telephone">
          </label>

          <label>N° SIRET<br/>
              <input type="text" placeholder="Laissez vide si vous n'en avez pas" v-model="userData.siret">
          </label>
        </div>
        <img src="/img/illus_man_left.svg" alt="" style="width: 160pt; transform: scale(1.25)">
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
  input[type="text"]
    width 150pt
</style>

<script>
export default {
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('api_userdata') || '{}')
    }
  },
  watch: {
    userData: { handler: function(nextValue) {
      localStorage.setItem('api_userdata', JSON.stringify(nextValue))
    }, deep: true}
  }
}
</script>