<template>
  <div style="flex: 1; display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 2rem;">
    <h1>Oups...</h1>
    <img src="/img/illus_man_dunno.svg" style="height: 256pt; width: 256pt;">
    <p>Votre appareil ne dispose pas d'un écran suffisamment large pour afficher l'application.</p>
  </div>
</template>

<script>

</script>

<style lang="stylus">
:root
  --color-primary #ffbb00
  --color-primary-dark #eba006
  --color-alternative #523c1b
  --color-alternative-dark #332714

html
body
  padding 0
  margin 0
  width 100vw
  height 100vh
  display flex
  overflow hidden
  background #fbfbfb url('/img/motif_honeycomb_gray.svg') center center
  background-size 150px

#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color #2c3e50

  display flex
  flex 1
  overflow hidden
  flex-direction column
  
h1::before
h1::after
  content url(/img/app-icon.svg)
  display inline-block
  height 2rem
  width 2rem
  vertical-align middle
  margin 0 10px
h1::after
  transform rotateY(180deg)
</style>
