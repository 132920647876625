<template>
  <div style="padding: 2rem;">
      <h1>Sauvegarde et restauration</h1>
      <fieldset>
          <legend>Sauvegarde</legend>
          <small style="display: block; padding: .5rem; background: rgba(0,0,0,.025); border-radius: .5rem; margin-bottom: 1rem">
              Votre application fonctionne intégralement dans votre navigateur internet. Pour éviter toute suppression accidentelle des données, il est important de les sauvegarder régulièrement sur un support amovible ou un service de stockage cloud.
          </small>
          <button @click="getBackup()" class="primary">Exporter les données</button>
      </fieldset>
      <br/>
      <fieldset>
          <legend>Import</legend>
          <small style="display: block; padding: .5rem; background: rgba(0,0,0,.025); border-radius: .5rem; margin-bottom: 1rem">
              Pour restaurer les données depuis un fichier de sauvegarde, indiquez le fichier à reprendre dans le champ ci-dessous, puis, s'il est conforme, cliquez sur le bouton <b>Importer les données</b>.
          </small>
          <input type="file" accept="application/json" @change="onFileChange" :disabled="readingRestoreFile">
          <button class="primary" :disabled="readingRestoreFile || !jsonToProcess || importing" @click="processImport">Importer les données</button>
          
          <div>{{runningMessage}}</div>
      </fieldset>
  </div>
</template>

<script>
import PouchDB from 'pouchdb'
export default {
    data() {
        return {
            readingRestoreFile: false,
            runningMessage: '',
            jsonToProcess: null
        }
    },
    methods: {
        onFileChange(e) {
            this.runningMessage = ''
            const file = e.target.files[0]
            if(file) {
                this.runningMessage = 'Lecture du fichier'
                this.readingRestoreFile = true
                this.$helpers.readFile(file).then((content) => {
                    try {
                        const backup = JSON.parse(content)
                        if(!backup.localStorage || !backup.databases) {
                            this.runningMessage = `impossible d'importer ce fichier`
                            throw new Error('could not read file: bad format')
                        }
                        this.jsonToProcess = backup
                        this.runningMessage = `Prêt pour l'import`
                    } catch(e) {
                        this.runningMessage = `impossible de lire ce fichier`
                        throw new Error('could not read file')
                    }
                    this.readingRestoreFile = false
                }).catch(() => {
                    this.readingRestoreFile = false
                })
            }
            e.target.value = null
        },
        async processImport() {
            this.runningMessage = ''
            this.importing = true
            // importing localStorage
            this.runningMessage = `Import des données de stockage local`
            ;['api_setupdate', 'api_userdata'].forEach(key => {
                localStorage.setItem(key, JSON.stringify(this.jsonToProcess.localStorage[key]))
            })
            // importing databases
            for(const dbname of this.$helpers.getDbList()) {
                console.log('destroying database', dbname)
                await this.$DB[dbname].destroy()
                console.log('creating database', dbname)
                this.$DB[dbname] = await new PouchDB(dbname, { auto_compaction: true })
                this.runningMessage = `Import des données pour la base "${dbname}"`
                console.log('importing data into', dbname)
                await this.$DB[dbname].bulkDocs(
                    this.jsonToProcess.databases[dbname].map(x => {
                        delete x._rev
                        return x 
                    })
                ).then(function(results) { console.log(JSON.stringify(results));})
            }
            this.importing = false
            this.runningMessage = `Import terminé ! l'application va se recharger...`
            setTimeout(() => {
                document.location.href = '/'
            }, 1000)

        },
        async getBackup() {
            const backup = {}
            // localStorage
            backup.localStorage = {
                api_setupdate: localStorage.getItem('api_setupdate'),
                api_userdata: JSON.parse(localStorage.getItem('api_userdata'))
            }
            // databases
            backup.databases = {}
            
            for(const dbname of ['ruchers', 'ruches', 'etiquettes', 'etiquettesIllustrations']) {
                await this.$DB[dbname].allDocs({include_docs: true}).then(result => {
                    backup.databases[dbname] = result.rows.map(r => r.doc)
                })
            }

            this.$helpers.downloadFile(`sauvegarde_apiculture_${new Date().toISOString().substr(0,10)}_${new Date().toLocaleTimeString().replace(/[:]/m, '-')}.json`, JSON.stringify(backup))

        },
        restoreBackup() {

        }
    }
}
</script>

<style>

</style>