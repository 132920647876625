<template>
<div>
  <form style="width: 100%; margin: 1rem auto; max-width: calc(4rem + 520pt); align-self: flex-start" id="ruchedetails">
    <div style="display: flex; align-items: center; padding: .5rem; border-bottom: 1pt solid rgba(0,0,0,.1); margin-bottom: 1rem;">
      <img :src="`/img/ic_lieu_${rucher.environnement}.svg`" alt="" style="height: 1.2rem; width: 1.2rem; margin: 0 .3rem">
      <b>{{rucher.nom || rucher.ville}}</b>
      <div style="flex: 1"></div>
      <router-link class="button" :to="`/ruchers/${$route.params.id}`" style="display: flex; align-items: center">
        Revenir au rucher
        <img src="/img/ic_ruches.svg" alt="" style="height: 1.5rem; width: 1.5rem; margin: auto -.25rem auto .25rem; transform: scale(1.25)" >
      </router-link>
    </div>
    <img src="/img/illus_ruche.svg" alt="" :style="`height: 100pt; width: 100pt; border-radius: 50%; background-image: url('/img/illus_lieu_${rucher.environnement}.svg');`">
    <div style="height: 2rem">
      <h2 style="margin-bottom: -.5rem;">{{ruche.code || 'Ruche non identifiée'}}
        <HelpIcon v-if="isModif">
            <template v-slot:help>
              <h3>Code d'identification</h3>
              <p>Si la ruche est identifiée par un code, ce dernier peut être saisi dans ce champ. Il peut aussi bien s'agir d'un simple numéro (01, 02, etc...).</p>
              <h3>Description de la ruche</h3>
              <p>Vous pouvez donner une description un peu plus longue à cette ruche, qui sera indiquée sous son code d'identification.</p>
              <br/>
              <p>Au moins une des deux informations est nécessaire pour identifier la ruche.</p>
            </template>
          </HelpIcon>
      </h2>
      <small style="opacity: .66; text-transform: uppercase; font-size: .66rem;">{{ruche.nom || ''}}</small>
    </div>
    <div style="padding: 2rem;">
      <div v-if="isModif">
        <label>
          Code d'identification<br/>
          <input type="text" placeholder="XX" v-model="ruche.code">
        </label>
        <label>
          Description de la ruche
          <br/>
          <input type="text" placeholder="(Facultatif)" v-model="ruche.nom">
        </label>
        <br/>
        <fieldset>
          <legend>Colonie</legend>
          <label>
            Espèce<br/>
            <input type="text" placeholder="Espèce d'abeille" v-model="ruche.colonie.espece">
          </label>
          <label>
            Marquage<br/>
            <select v-model="ruche.colonie.marquage">
              <option :value="undefined">Sans marquage</option>
              <option value="jaune">Jaune</option>
              <option value="vert">Vert</option>
              <option value="bleu">Bleu</option>
              <option value="rouge">Rouge</option>
              <option value="blanc">Blanc</option>
            </select>
          </label>
          <label>
            Année<br/>
            <input type="number" v-model="ruche.colonie.annee" min="1900" max="9999" step="1" placeholder="Année de fondation de la colonie" />
          </label>
          <label>
            Affectation<br/>
            <input type="text" placeholder="Affectation de la colonie" v-model="ruche.colonie.affectation">
          </label>
          <label>
            <div>
              Date de clippage <a href="#" @click.prevent.stop="ruche.colonie.clippage = ''" style="float: right; text-decoration: none">Pas de clippage</a>
            </div>
            <input type="date" placeholder="Laisser vide si pas de clippage" v-model="ruche.colonie.clippage">
          </label>
          <label>
            Origine<br/>
            <input type="text" placeholder="Origine de la colonie" v-model="ruche.colonie.origine">
          </label>
          <label>
            Notes<br/>
            <textarea v-model="ruche.colonie.notes" style="min-height: 6rem"></textarea>
          </label>
        </fieldset>
        <div style="display :flex; align-items: center; justify-content: center; padding: .5rem">
          <button type="button" class="primary" @click="update">Mettre à jour</button>
          &nbsp;
          <button type="button" @click="remove">Supprimer</button>
          </div>
      </div>
      <div v-else>
        <div>
          <span class="tagbadge" title="Espèce" v-if="ruche.colonie.espece">
              <span class="tagdesc" ><img alt="" src="/img/app-icon.svg" /></span>
              {{ruche.colonie.espece}}
          </span> 
          &nbsp;
          <span class="tagbadge" v-if="ruche.colonie.annee">
              <span class="tagdesc" >Année</span>
              {{ruche.colonie.annee}}
          </span> 
          &nbsp;
          <span :class="`tagbadge marquage ${ruche.colonie.marquage}`" v-if="ruche.colonie.marquage">
              <span class="tagdesc" >Marquage</span>
              {{ruche.colonie.marquage}}
          </span>
          &nbsp;
          <span class="tagbadge" v-if="ruche.colonie.clippage">
              <span class="tagdesc" >Clippage</span>
              {{new Date(ruche.colonie.clippage).toLocaleDateString()}}
          </span>
        </div>
        <fieldset v-if="ruche.colonie.notes">
          <legend>Notes</legend>
          <div style="white-space: pre-line">{{ruche.colonie.notes}}</div>
        </fieldset>
        <br/>
        <div>
          <button type="button" @click.prevent="() => { isModif = true }">Modifier</button>
        </div>
      </div>
    </div>
  </form>
  <router-view />
</div>
</template>

<script>
export default {
  data() {
    return {
      isModif: false,
      rucher: {},
      ruche: {colonie:{}}
    }
  },
  methods: {
    update() {
      this.$DB['ruches'].put(this.ruche).then((r) => {
        this.ruche._rev = r.rev
        this.$emit('change')
        this.isModif = false
      })
    },
    remove() {
      if(confirm('Supprimer cette ruche ?')) {
        this.$DB['ruches'].get(this.ruche._id).then((doc) => {
          return this.$DB['ruches'].remove(doc).then(() => {
            this.$emit('change')
            this.$router.push(`/ruchers/${this.rucher._id}`)
          })
        })
      }
    },
  },
  mounted() {
    this.$DB['ruches'].get(this.$route.params.ruche_id).then((doc) => {
      this.ruche = doc
      if(doc._rev.indexOf('1-') == 0) {
        this.isModif = true
      }
      this.$DB['ruchers'].get(this.ruche.rucher).then((doc) => {
        this.rucher = doc
      })
    })
  }
}
</script>

<style lang="stylus" scoped>
  .tagbadge
    color #555
    padding 0 .75rem 0 0
    border-radius .3rem
    background rgba(0,0,0,.05)
    display inline-flex
    align-items center
    overflow hidden
    line-height 1.75rem
    height 1.75rem
    box-shadow 0 0 0 1px rgba(0,0,0,.1)
    position relative
    &::before
      content ''
      display block
      height .75rem
      position absolute
      top 0
      left 0
      right 0
      border-radius .2rem
      background linear-gradient(to bottom, rgba(255,255,255,.75), rgba(255,255,255,.25))
    .tagdesc
      border-radius 0
      background var(--color-primary)
      background linear-gradient(to bottom, var(--color-primary), var(--color-primary-dark))
      color white
      text-shadow 0 0.5px 2px var(--color-alternative)
      box-shadow 0 2pt 5pt rgba(0,0,0 .2), inset 0 -1pt 3pt rgba(0,0,0 .15)
      padding 0 .3rem 0 .4rem
      margin-right .3rem
      min-width 1rem
      display block
      font-size .7rem
      text-transform uppercase
      
      img
        vertical-align middle
.marquage
  font-size .7rem
  text-transform uppercase
  &.bleu
    background royalblue
    color white
  &.blanc
    background white
    color #ddd
    text-shadow 1px 1px 0 rgba(0,0,0,.4)
  &.jaune
    background gold
    color #brown
  &.rouge
    background #dd4444
    color white
    text-shadow 1px 1px 0 rgba(0,0,0,.4)
  &.vert
    background #44dd44
    color white
    text-shadow 1px 1px 0 rgba(0,0,0,.4)
</style>