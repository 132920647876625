<template>
  <div style="flex: 1; display: flex; overflow: auto">
      <div id="menu">
        <h1 style="white-space: nowrap; font-size: 1.4rem;">Mes ruchers</h1>
        <router-link to="/ruchers/accueil">
          Vue d'ensemble
        </router-link>
        <br>
        <router-link v-for="r in ruchers" :key="r._id" :to="`/ruchers/${r._id}`" style="padding-left: .15rem; overflow: hidden;">
          <img :src="`/img/ic_lieu_${r.environnement}.svg`" alt="" style="height: 1.2rem; width: 1.2rem; margin: 0 .3rem">
          <div style="text-align: left; line-height: 100%; flex: 1; overflow: hidden;">
            <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{r.nom || 'Rucher sans nom'}}</div>
            <small style="opacity: .66; font-size: .66rem; display: block;">{{r.ville || 'Emplacement inconnu'}}</small>
          </div>
          <div style="font-size: .66rem; padding: .3rem .5rem; border-radius: .3rem; background: var(--color-alternative); margin: 0 .25rem; color: white; opacity: .75">{{r.nbRuches}}</div>
        </router-link>
        <br>
        <router-link class="primary" to="/ruchers/nouveau">Nouveau rucher</router-link>
      </div>
      <router-view :key="$route.fullPath" style="flex: 1" @change="init"/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruchers: []
    }
  },
  methods: {
    init() {
      this.$DB['ruchers'].allDocs({include_docs: true}).then(async (docs) => {
        const ruches = (await this.$DB['ruches'].allDocs({include_docs: true})).rows.map(r => r.doc) || []
        this.ruchers = docs.rows.map((d) => {
          const r = d.doc
          r.nbRuches = ruches.filter((ruche) => ruche.rucher == r._id).length
          return r
        })
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="stylus" scoped>
.about
  flex 1
  display flex
  flex-direction column
#menu
  margin .5rem
  margin-right 1.5rem
  background var(--color-alternative)
  padding 1rem .5rem
  border-radius .5rem
  width 170pt
  display flex
  flex-direction column
  color white
  align-self flex-start
  box-shadow 0 .2rem .5rem rgba(0,0,0,.25), inset 0 1pt 0 rgba(255,255,255,.33)
  border-top inset .3rem var(--color-alternative-dark)
  a
    display flex
    align-items center
    background var(--color-alternative-dark)
    color white
    border-radius .5rem
    padding .25rem .25rem .25rem .75rem
    text-decoration none
    &~a
      margin-top 4px
    &.router-link-active
      background rgba(255,255,255,.75)
      color var(--color-alternative)
    &.primary
      color white
      text-shadow 0 0.5px 2px var(--color-alternative)
      background var(--color-primary)
      background linear-gradient(to bottom, var(--color-primary), var(--color-primary-dark))
      position relative
      box-shadow 0 2pt 5pt rgba(0,0,0 .2), inset 0 -1pt 3pt rgba(0,0,0 .15)
      padding .25rem .7rem
      align-self center
      &::before
        content ''
        display block
        height .75rem
        position absolute
        top 1pt
        left 1pt
        right 1pt
        border-radius 1rem
        background linear-gradient(to bottom, rgba(255,255,255,.75), rgba(255,255,255,.25))
</style>
