<template>
  <div id="nav">
    <router-link to="/">
      <img alt="Vue logo" src="/img/app-icon.svg" style="height: 2rem; width: 2rem; transform: scale(1.25);">
    </router-link>

    <router-link to="/ruchers">Exploitation</router-link>
    <!-- <router-link to="/stock">Matériel</router-link> -->
    <!-- <router-link to="/pharmacie">Pharmacie</router-link> -->
    <router-link to="/etiquettes">Étiquettes</router-link>
    <div style="flex: 1"></div>
    <router-link to="/parametres">Paramètres</router-link>
    
  </div>
  <router-view style="padding: 0 calc(50% - 450pt);"/>
</template>

<script>
export default {
  mounted() {
    if(!localStorage.getItem('api_setupdate')) {
      this.$router.replace('/setup')
    }
  }
}
</script>

<style lang="stylus">
:root
  --color-primary #ffbb00
  --color-primary-dark #eba006
  --color-alternative #523c1b
  --color-alternative-dark #332714

html
body
  padding 0
  margin 0
  width 100vw
  height 100vh
  display flex
  overflow hidden
  background #fbfbfb url('/img/motif_honeycomb_gray.svg') center center
  background-size 150px
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color #2c3e50

  display flex
  flex 1
  overflow hidden
  flex-direction column

  #nav
    padding .5rem
    box-shadow 0 0 .5rem 1pt rgba(0,0,0,.25)
    display flex
    z-index 2
    background white
    a
      text-decoration none
      outline none
      color var(--color-alternative)
      margin 0 .5rem
      padding .2rem .7rem
      display flex
      align-items center
      &.router-link-active
        color white
        text-shadow 0 0.5px 2px var(--color-alternative)
        background var(--color-primary)
        background linear-gradient(to bottom, var(--color-primary), var(--color-primary-dark))
        border-radius .5rem
        position relative
        box-shadow 0 2pt 5pt rgba(0,0,0 .2), inset 0 -1pt 3pt rgba(0,0,0 .15)
        &::before
          content ''
          display block
          height .75rem
          position absolute
          top 1pt
          left 1pt
          right 1pt
          border-radius .35rem
          background linear-gradient(to bottom, rgba(255,255,255,.75), rgba(255,255,255,.25))

label
  position relative
  font-size .85rem
  color var(--color-alternative)
  display flex
  flex-direction column
  text-align left
  text-indent .3rem
  margin-bottom .5rem
input[type="text"]
input[type="number"]
input[type="tel"]
input[type="date"]
input[type="color"]
textarea
select
  padding .3rem
  background rgba(0,0,0,.025)
  border-radius .4rem
  border 1pt solid rgba(0,0,0,.2)
  box-shadow 0 0 3pt rgba(0,0,0,.05), inset 0 2pt .25rem rgba(0,0,0,.1)
  outline none
  &:focus
    border-color var(--color-primary)
textarea
  width 100%
  box-sizing border-box
  min-height 3rem
  font-family inherit
input[type="color"]
  padding 1px!important
form
  border-radius 2pt
  border 1pt solid rgba(0,0,0,.1)
  box-shadow 0 3pt .5rem rgba(0,0,0,.2)
  background white

::placeholder
  font-size .8rem
  opacity .5
  font-style italic

h1::before
h1::after
  content url(/img/app-icon.svg)
  display inline-block
  height 2rem
  width 2rem
  vertical-align middle
  margin 0 10px
h1::after
  transform rotateY(180deg)

button
.button
[button]
  border none
  border-radius .5rem
  position relative
  padding .5rem .85rem
  align-self center
  font-size 1rem
  cursor pointer
  background #f2f2f2
  text-decoration none
  color inherit
  &.primary
    box-shadow 0 2pt 5pt rgba(0,0,0 .2), inset 0 -1pt 3pt rgba(0,0,0 .15)
    color white
    text-shadow 0 0.5px 2px var(--color-alternative)
    background var(--color-primary)
    background linear-gradient(to bottom, var(--color-primary), var(--color-primary-dark))
  &::before
    content ''
    display block
    height .75rem
    position absolute
    top 1pt
    left 1pt
    right 1pt
    border-radius 1rem
    background linear-gradient(to bottom, rgba(255,255,255,.75), rgba(255,255,255,.25))
  &[disabled]
    border-color transparent!important
    text-shadow none!important
    background none!important
    color rgba(0,0,0,.15)!important
    box-shadow 0 0 0 1px rgba(0,0,0,.1)!important
    background #fbfbfb!important
    cursor not-allowed!important
    &::before
    &::after
      display none!important

fieldset
  border-radius .35rem
  border 1pt solid #eee
  text-align left
  legend
    padding .25rem .5rem
    background #eee
    border-radius 1rem
    font-size .9rem
</style>
