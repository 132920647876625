<template>
    <div v-if="!ready"></div>
    <div class="graphic" v-else-if="ruchers.length">
        <div v-for="r in ruchers" :key="r._id" class="bar" :style="`height: ${calculateHeightPercent(r)}%`">
            <div class="label">{{r.nbRuches}} ruche{{r.nbRuches > 1 ? 's' : ''}}</div>
            <div class="legend">{{r.nom}}</div>
        </div>
    </div>
    <div v-else class="welcome">
        <p>Il n'y a pas encore de données ici.</p>
        <img src="/img/illus_man_dunno.svg" alt="" style="height: 256px">
        <p>Pour afficher un graphique, vous devez d'abord <router-link class="primary" to="/ruchers/nouveau">ajouter au moins un rucher</router-link>.</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            ruchers: [],
            ready: false
        }
    },
    methods: {
        calculateHeightPercent(rucher) {
            const biggest = this.ruchers.map(r => r.nbRuches).sort((a,b) => b > a ? 1 : -1)[0] * 1.1
            return rucher.nbRuches/biggest*100
        }
    },
    mounted() {
        this.$DB['ruchers'].allDocs({include_docs: true}).then(async (docs) => {
            const ruches = (await this.$DB['ruches'].allDocs({include_docs: true})).rows.map(r => r.doc) || []
            this.ruchers = docs.rows.map((d) => {
                const r = d.doc
                r.nbRuches = ruches.filter((ruche) => ruche.rucher == r._id).length
                return r
            }).sort((a, b) => b.nbRuches > a.nbRuches ? 1 : -1)
            .slice(0,5)
            this.ready = true
        })
    }
}
</script>

<style lang="stylus" scoped>
.graphic
    width 80%
    display flex
    align-items flex-end
    justify-content space-evenly
    height 200pt
    border-bottom 1pt solid rgba(0,0,0,.1)
    margin 1rem auto 3rem auto
    .bar
        padding .5rem
        margin 0 .5rem
        position relative
        flex 1
        max-width 80pt
        border-radius .5rem .5rem 0 0
        font-size .8rem
        box-shadow inset 0 1rem 0 rgba(0,0,0,.1)
        &:nth-of-type(1)
            background #ffdc7c
        &:nth-of-type(2)
            background #b7ff7c
        &:nth-of-type(3)
            background #7cd1ff
        &:nth-of-type(4)
            background #d5afea
        &:nth-of-type(5)
            background #ffc1c1
        .label
            position absolute
            bottom .25rem
            left 0
            right 0
            text-align center
            opacity .75
        .legend
            position absolute
            top calc(100% + .5rem)
            left -1rem
            right -1rem
            text-align center
            line-height 100%
</style>