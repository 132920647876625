<template>
  <div
    id="apercu"
    :key="JSON.stringify(etiquette)"
    :style="`width: ${etiquette.dimensions.longueur}mm; height: ${
      etiquette.dimensions.hauteur
    }mm; padding: ${etiquette.dimensions.padding/2 || 1}mm; margin: ${etiquette.dimensions.margin || 1}mm; background: ${afficheIllustration()};`"
  >
    <div id="liseret"
    :style="`padding: ${etiquette.dimensions.padding/2 || 1}mm; ${calculeBordure()}`">
      <div id="denomination">
        <div id="nomRucher">{{ afficheNomRucher() }}</div>
        <div
          id="typeMiel"
          :style="`color: ${etiquette.style.useCustomTextColor ? etiquette.style.textColor : $helpers.couleurTypeMiel(etiquette.typeMiel)}`"
        >
          {{ afficheTypeMiel() }}
        </div>
      </div>

      <div id="ddm" :style="`width: ${etiquette.dimensions.hauteur}mm; padding-bottom: ${2 + (etiquette.dimensions.padding/2 || 0)}mm;`">
        <span>À consommer de préférence avant le :</span>
        <strong :class="etiquette.typeDdm">{{ afficheDdm() }}</strong>
      </div>

      <div id="mentions">{{ etiquette.mentions }}</div>

      <div style="display: flex; align-items: flex-end; width: calc(100% - 2rem); padding-right: 2rem;">
        <div id="poidsNet"><span>Poids net :</span>{{ affichePoidsNet() }}</div>
        <div style="flex: 1; margin-bottom: 1mm;">
          <div id="raisonSociale">
            {{ userData.prenom }} {{ userData.nom }}
            {{ userData.telephone ? " - " + userData.telephone : "" }}
          </div>
          <div id="adresse">
            <div>
              {{ userData.adresse1 }}
              {{ userData.adresse2 }}
              {{ userData.adresse3 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const etiquetteDefaut = {
  nom: "",
  typeMiel: "",
  typeDdm: "calcul",
  illustration: "",
  rucher: "",
  poidsNet: 500,
  moisDdm: 18,
  mentions: "",
  style: {
    useCustomTextColor: false,
    textColor: "#000000"
  },
  dimensions: {
    hauteur: 40,
    longueur: 70,
    padding: 0,
    margin: 0
  },
};
export default {
  name: "Etiquette",
  props: {
    data: {
      default: () => ({dimensions:{}}),
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("api_userdata") || "{}"),
      etiquette: {...etiquetteDefaut, ...this.data},
      ruchers: [],
    };
  },
  methods: {
    calculeBordure() {
      const color = this.etiquette.dimensions.liseretColor || 'rgba(0,0,0,.5)'
      switch(this.etiquette.dimensions.liseretType) {
        case 'dotted':
          return `border: 1px dotted ${color};`;
        case 'lined':
          return `border: 1px solid ${color};`;
        case '2lined':
          return `border: 3px double ${color};`;
        
        default: 
          return '';
      }
    },
    afficheNomRucher() {
      const nomRucher = (
        this.ruchers.find((r) => r._id == this.etiquette.rucher) || {}
      ).nom;
      return (nomRucher ? `Rucher ${nomRucher}` : "").replace(
        /rucher rucher/gim,
        "Rucher"
      );
    },
    afficheTypeMiel() {
      return this.$helpers.afficheTypeMiel(this.etiquette.typeMiel);
    },
    afficheDdm() {
      const d = new Date();
      switch (this.etiquette.typeDdm) {
        case "couvercle":
          return "(voir couvercle)";
        case "dessous":
          return "(voir sous le pot)";
        case "calcul":
        default:
          d.setMonth(d.getMonth() + parseInt(this.etiquette.moisDdm, 10));
          return d.toLocaleDateString();
      }
    },
    affichePoidsNet() {
      const poidsGrammes = this.etiquette.poidsNet;
      if (poidsGrammes > 999) {
        return `${parseFloat(poidsGrammes / 1000)
          .toFixed(2)
          .replace(/[0]+$/, "")
          .replace(/[.]$/, "")}kg`;
      } else {
        return `${poidsGrammes}g`;
      }
    },
    afficheIllustration() {
      switch (this.etiquette.illustration) {
        case "":
          return "white";
        case "alveoles":
          return "url(/img/motif_honeycomb.svg)";
        case "apiculteur":
          return "white url(/img/backgrounds/apiculteur.svg); background-size: 7rem 100%; background-position-y: calc(100% + 1rem); background-position-x: -1rem; background-repeat: no-repeat;";
        case "ruche":
          return "url(/img/backgrounds/ruche.png); background-position: top left; background-repeat: no-repeat; background-size: 25%";
        case "abeille":
          return "url(/img/backgrounds/abeille.png); background-position: top left; background-repeat: no-repeat; background-size: 25%";
        case "icon":
          return this.$helpers.illustrationTypeMiel(this.etiquette.typeMiel + '_icon');
        default:
          return this.$helpers.illustrationTypeMiel(this.etiquette.typeMiel);
      }
    },
    initRuchers() {
      this.$DB["ruchers"].allDocs({ include_docs: true }).then((docs) => {
        this.ruchers = docs.rows.map((r) => r.doc);
      });
    },
  },
  mounted() {
    this.initRuchers();
  },
  watch: {
      data: {
          handler: function(nv) {
            this.etiquette = {...etiquetteDefaut, ...nv}
            this.$forceUpdate()
        },
        deep: true
      }
  }
};
</script>

<style scoped lang="stylus">
small {
  opacity: 0.5;
}

#aperçu {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  margin: auto;
  box-sizing: border-box;
  padding-right: 2rem;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  text-shadow: 1mm 0 0 rgba(255, 255, 255, 0.5), -1mm 0 0 rgba(255, 255, 255, 0.5), 0 1mm 0 rgba(255, 255, 255, 0.5), 0 -1mm 0 rgba(255, 255, 255, 0.5), 1px 1px 0 rgba(255, 255, 255, 0.5), -1px -1px 0 rgba(255, 255, 255, 0.5), 1px -1px 0 rgba(255, 255, 255, 0.5), -1px 1px 0 rgba(255, 255, 255, 0.5);
  -webkit-print-color-adjust: exact;
  overflow: hidden;
  color: black;
  text-align: center;
}

#liseret {
  display: flex;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

#typeMiel {
  font-size: 5.5mm;
  font-weight: bold;
}

#nomRucher {
  font-size: 3.5mm;
  opacity: 0.85;
  font-style: italic;
}

#ddm {
  font-size: 1.7mm;
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  transform-origin: bottom left;
  transform: translateX(100%) rotate(-90deg);
  margin: 0;
  line-height: 2.25mm;
  background: rgba(255, 255, 255, 0.5);
  padding: 2mm;
  z-index: 0;
  opacity: 0.75;
  text-align: center;
  box-sizing: border-box;

  strong {
    font-size: 3mm;
    line-height: 3.5mm;
    display: block;
    font-weight: normal;
    opacity: 0.6;

    &.calcul {
      font-family: monospace;
      opacity: 1;
    }
  }
}

#poidsNet {
  span {
    font-size: 1.9mm;
    font-weight: normal;
    display: block;
    margin-bottom: -1mm;
  }

  font-size: 4mm;
  font-weight: bold;
  display: block;
  text-align: left;
  padding-left: 1mm;
  margin-top -2.5mm
  width: 2rem;
  white-space: nowrap;
}

#raisonSociale {
  font-size: 2.25mm;
  font-weight: bold;
}

#adresse {
  font-size: 1.8mm;
}

#mentions {
  font-size: 1.8mm;
  white-space: pre-line;
  text-align: center;
}

#denomination {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
</style>