<template>
  <div id="HelpIcon">
      <button type="button" @click.stop.prevent="() => { toggle() }" title="Besoin d'aide ?">
        <img src="/img/illus_man_tips_small.svg" alt="" style="height: 32px">
      </button>
      <div class="modaleWrapper" v-if="open === true" @click.stop.prevent>
            <div class="modale">
                <button class="closeButton" type="button" @click.stop.prevent="() => { toggle() }">&times;</button>
                <img src="/img/illus_man_reading.svg" alt="" style="height: 256px; position: absolute; left: -150px; bottom: -50px">
                <slot name="help"/>
            </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            open: false
        }
    },
    methods: {
        toggle() {
            this.open = !this.open
            this.$emit('toggle')
        }
    }
}
</script>

<style scoped lang="stylus">
#HelpIcon
    display inline-block
    font-size 1rem
    font-weight normal
button
    outline none
    padding 0
    background transparent
    border none
    position relative
#HelpIcon>button
    &::before
        display none
    &:hover > img
        transform-origin center 80%
        animation 1s ease-in-out bounce infinite
.modaleWrapper
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0,0,0,.2)
    backdrop-filter blur(2px)
    display flex
    align-items center
    justify-content center
    z-index 999
    .modale
        text-align justify
        box-sizing border-box
        min-width 300px
        max-width 40vw
        min-height 200px
        max-height 80%
        padding 2rem 4rem
        background white
        border-radius .5rem
        position relative
        box-shadow 0 0 1rem 1px rgba(0,0,0,.3)
        .closeButton
            position absolute
            padding .75rem
            opacity .5
            font-size 1.1rem
            top 0
            right 0
            z-index 999
            transition .1s
            &:hover
                opacity .9
@keyframes bounce
    0%
    100%
        transform scale(1.25) rotate(0deg) translateY(6%)
    25%
        transform scale(1.25) rotate(-5deg) translateY(6%)
    75%
        transform scale(1.25) rotate(5deg) translateY(6%)
</style>