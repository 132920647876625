<template>
  <div style="overflow: auto">
    <h1 v-if="etiquette._id">Modifier l'étiquette</h1>
    <h1 v-else>Nouvelle étiquette</h1>
    <form style="padding: 2rem; max-width: 540pt; margin: auto" @submit.prevent="saveEtiquette">
      <label>
        Nom de l'étiquette
        <input type="text" v-model="etiquette.nom" placeholder="Sans nom" />
        <small
          >(Le nom ne sera pas visible sur l'étiquette, il sert simplement à
          l'identifier)</small
        >
      </label>

      <br />

      <div style="display: flex; align-items: flex-start">
        <div style="flex: 1">
          <fieldset>
            <legend>Contenu du pot</legend>
            <label>
              Dénomination de vente
              <select v-model="etiquette.typeMiel">
                <option value="">Apiculteur (par défaut)</option>
                <option value="acacia">Acacia</option>
                <option value="chataigner">Châtaigner</option>
                <option value="fleurs">Fleurs</option>
                <option value="foret">Forêt</option>
                <option value="montagne">Montagne</option>
              </select>
            </label>
            <label>
              Provenance
              <select v-model="etiquette.rucher" required>
                <option value="" disabled>Choisir un rucher</option>
                <option v-for="r in ruchers" :key="r._id" :value="r._id">
                  {{ r.nom }} {{ r.ville ? `(${r.ville})` : "" }}
                </option>
              </select>
            </label>
            <label>
              Quantité nette
              <input
                v-model="etiquette.poidsNet"
                type="number"
                step="1"
                min="0"
                placeholder="Poids en grammes"
              />
              <small>(en grammes)</small>
            </label>

            <label>
              Date de Durabilité Minimale (DDM)
              <select v-model="etiquette.typeDdm">
                <option value="calcul">
                  Sur l'étiquette (calcul automatique)
                </option>
                <option value="couvercle">
                  Sur le couvercle (à ajouter manuellement)
                </option>
                <option value="dessous">
                  Sous le pot (à ajouter manuellement)
                </option>
              </select>
              <div>
                <input
                  :disabled="etiquette.typeDdm != 'calcul'"
                  type="number"
                  v-model="etiquette.moisDdm"
                  min="0"
                  style="
                    width: 6ch;
                    margin-left: -0.25rem;
                    margin-right: 0.25rem;
                  "
                />
                <small
                  >(Nombre de mois à compter de la date d'impression)</small
                >
              </div>
            </label>

            <label>
            <div style="display: flex; align-items: center">
              <input type="checkbox" v-model="etiquette.style.useCustomTextColor" /> Couleur de texte personnalisée
            </div>
            <div style="display: flex; align-items: center">
              <input type="color" 
                v-model="etiquette.style.textColor"
                :disabled="!etiquette.style.useCustomTextColor"
              />
              <small>{{etiquette.style.textColor}}</small>
              <HelpIcon>
              <template v-slot:help>
                <h3>Couleur du texte</h3>
                <p>Par défaut, chaque type de miel est inscrit avec une couleur prédéfinie.</p>
                <p>Vous pouvez choisir votre propre couleur pour inscrire le nom du type de miel sur votre étiquette en la choisissant ici !</p>
              </template>
            </HelpIcon>
            </div>
            </label>
          </fieldset>
          <br />
          <fieldset>
            <legend>Mentions spéciales</legend>
            <textarea v-model="etiquette.mentions"></textarea>
          </fieldset>
        </div>
        <div style="flex: 1">
          <fieldset>
            <legend>Dimensions</legend>
            <div style="display: flex; align-items: center">
              <label>
                Longueur
                <input
                  v-model="etiquette.dimensions.longueur"
                  type="number"
                  step="5"
                  min="40"
                  placeholder="Longueur"
                  style="width: 5rem; text-align: center"
                />
                <small>(en millimètres)</small>
              </label>
              &nbsp;&times;&nbsp;
              <label>
                Hauteur
                <input
                  v-model="etiquette.dimensions.hauteur"
                  type="number"
                  step="5"
                  min="30"
                  placeholder="Hauteur"
                  style="width: 5rem; text-align: center"
                />
                <small>(en millimètres)</small>
              </label>
            </div>
          </fieldset>
          <br/>
          <fieldset>
          <legend>Marges</legend>
          <div style="display: flex; align-items: center">
              <label>
                Intérieure
                <input
                  v-model="etiquette.dimensions.padding"
                  type="number"
                  step="1"
                  min="1"
                  max="10"
                  placeholder="0"
                  style="width: 5rem; text-align: center"
                />
                <small>(en millimètres)</small>
              </label>
              &nbsp;
              <label>
                Découpe
                <input
                  v-model="etiquette.dimensions.margin"
                  type="number"
                  step="1"
                  min="1"
                  max="10"
                  placeholder="0"
                  style="width: 5rem; text-align: center"
                />
                <small>(en millimètres)</small>
              </label>
              </div>
              <label>
                Liseret
                <div style="display: flex; align-items: center;">
                  <select v-model="etiquette.dimensions.liseretType" style="flex: 1">
                    <option :value="undefined">aucun</option>
                    <option value="dotted">Pointillé</option>
                    <option value="lined">Ligne simple</option>
                    <option value="2lined">Ligne double</option>
                  </select>
                  &nbsp;
                  <input type="color" v-model="etiquette.dimensions.liseretColor" />
                </div>
              </label>
          </fieldset>
          <br />
          <fieldset>
            <legend>Image de fond</legend>
            <select v-model="etiquette.illustration" style="width: 100%">
              <option value="">Blanc (sans fond)</option>
            <optgroup label="Pleine largeur">
              <option value="alveoles">Alvéoles dorées</option>
              <option value="dynamique">Selon le type de miel (pleine largeur)</option>
            </optgroup>
            <optgroup label="Illustration">
              <option value="apiculteur">Apiculteur</option>
              <option value="ruche">Ruche</option>
              <option value="abeille">Abeille</option>
              <option value="icon">Selon le type de miel (illustration)</option>
            </optgroup>
            </select>
          </fieldset>
        </div>
      </div>
      <br />
      <button type="button" class="button btn" v-if="etiquette._id" style="color: red" @click="deleteEtiquette">Supprimer</button>
      &nbsp;
      <button class="button btn primary">Enregistrer</button>
      &nbsp;
      <router-link :to="{name: 'EtiquettePlanche', params: {idEtiquette: etiquette._id}}" v-if="etiquette._id" button>Imprimer</router-link>
      <br />
      <fieldset>
        <legend>Aperçu</legend>
        <div
          style="
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: flex-start;
          "
        >
          <Etiquette :data="etiquette" v-for="i of 4" :key="i"/>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import Etiquette from './EtiquetteElement.vue'
const etiquetteDefaut = {
  nom: "",
  typeMiel: "",
  typeDdm: "calcul",
  illustration: "",
  rucher: "",
  poidsNet: 500,
  moisDdm: 18,
  mentions: "",
  style: {
    useCustomTextColor: false,
    textColor: "#000000"
  },
  dimensions: {
    hauteur: 40,
    longueur: 70,
    padding: 1,
    margin: 1,
    liseretType: undefined,
    liseretColor: 'rgba(0,0,0,.5)'
  },
};
export default {
  components: {Etiquette},
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("api_userdata") || "{}"),
      etiquette: etiquetteDefaut,
      ruchers: [],
    };
  },
  methods: {
    afficheNomRucher() {
      const nomRucher = (
        this.ruchers.find((r) => r._id == this.etiquette.rucher) || {}
      ).nom;
      return (nomRucher ? `Rucher ${nomRucher}` : "").replace(
        /rucher rucher/gim,
        "Rucher"
      );
    },
    afficheTypeMiel() {
      return this.$helpers.afficheTypeMiel(this.etiquette.typeMiel);
    },
    afficheDdm() {
      const d = new Date();
      switch (this.etiquette.typeDdm) {
        case "couvercle":
          return "(voir couvercle)";
        case "dessous":
          return "(voir sous le pot)";
        case "calcul":
        default:
          d.setMonth(d.getMonth() + parseInt(this.etiquette.moisDdm, 10));
          return d.toLocaleDateString();
      }
    },
    affichePoidsNet() {
      const poidsGrammes = this.etiquette.poidsNet;
      if (poidsGrammes > 999) {
        return `${parseFloat(poidsGrammes / 1000)
          .toFixed(2)
          .replace(/[0]+$/, "")
          .replace(/[.]$/, "")}kg`;
      } else {
        return `${poidsGrammes}g`;
      }
    },
    initRuchers() {
      this.$DB["ruchers"].allDocs({ include_docs: true }).then((docs) => {
        this.ruchers = docs.rows.map((r) => r.doc);
      });
    },
    loadEtiquette(id) {
      this.$DB["etiquettes"].get(id).then((doc) => {
        this.etiquette = {...etiquetteDefaut, ...doc}
      })
    },
    deleteEtiquette() {
      if(confirm(`Supprimer ce modèle d'étiquette ?`)) {
        this.$DB['etiquettes'].get(this.etiquette._id).then((doc) => {
          return this.$DB['etiquettes'].remove(doc).then(() => {
            this.$router.replace({name: 'EtiquetteAccueil'})
          })
        })
      }
    },
    saveEtiquette() {
      if(this.etiquette._id) {
        // update
        this.$DB['etiquettes'].put(this.etiquette).then((r) => {
            this.$router.replace('/etiquettes/'+ r.id)
            this.loadEtiquette(r.id)
          })
      } else {
        // create
        this.$DB['etiquettes'].post(this.etiquette).then((r) => {
            this.$router.replace('/etiquettes/'+ r.id)
            this.loadEtiquette(r.id)
          })
      }
    }
  },
  mounted() {
    this.initRuchers();
    if(this.$route.params.id && this.$route.params.id != 'nouvelle') {
      //charger l'étiquette
      this.loadEtiquette(this.$route.params.id)
    } else {
      //ne rien faire, on crée une nouvelle étiquette
    }
  },
};
</script>

<style scoped lang="stylus">
small {
  opacity: 0.5;
}

#apercu {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  margin: auto;
  box-sizing: border-box;
  padding: 2mm;
  padding-right: 2rem;
  padding-left: 2.5rem;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-shadow: 1.5px 0 0 rgba(255,255,255,.5), -1.5px 0 0 rgba(255,255,255,.5), 0 1.5px 0 rgba(255,255,255,.5), 0 -1.5px 0 rgba(255,255,255,.5), 1px 1px rgba(255,255,255,.5), -1px -1px 0 rgba(255,255,255,.5), 1px -1px 0 rgba(255,255,255,.5), -1px 1px 0 rgba(255,255,255,.5);
  -webkit-print-color-adjust: exact;
  overflow: hidden;
  color black
  text-align center
}

#typeMiel {
  font-size: 5.5mm;
  font-weight: bold;
}

#nomRucher {
  font-size: 3.5mm;
  opacity: 0.85;
  font-style: italic;
}

#ddm {
  font-size: 1.7mm;
  position: absolute;
  bottom: 0;
  right: 0;
  display block
  transform-origin: bottom left;
  transform: translateX(100%) rotate(-90deg);
  margin: 0;
  line-height: 2.25mm;
  background: rgba(255, 255, 255, 0.33);
  padding: 2mm;
  z-index: 0;
  opacity .75
  text-align center
  box-sizing border-box

  strong {
    font-size: 3mm;
    line-height: 3.5mm;
    display: block;
    font-weight: normal;
    opacity: 0.6;

    &.calcul {
      font-family: monospace;
      opacity: 1;
    }
  }
}

#poidsNet {
  span {
    font-size: 1.9mm;
    font-weight: normal;
    display: block;
    margin-bottom: -1mm;
  }

  font-size: 4mm;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 1mm;
}

#raisonSociale {
  font-size: 2.25mm;
  font-weight bold
}

#adresse {
  font-size: 1.8mm;
}

#mentions {
  font-size: 1.8mm;
  white-space: pre-line;
  text-align: center;
}

#denomination
  align-items center
  display flex
  flex-direction column
  justify-content center
  min-height 70%
  flex 1
</style>