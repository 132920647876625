<template>
  <form style="padding: 2rem; margin: 0 auto; max-width: 520pt" id="ruches">
      <router-link 
        v-for="r in ruches"
        :key="r._id"
        :to="`/ruchers/${$route.params.id}/${r._id}`"
        class="button primary"
        style="overflow: hidden; display: inline-block;">
        <div style="display: flex; flex-direction: column; overflow: hidden">
            <img src="/img/ic_ruche.svg" alt="" style="height: 80pt; width: 80pt; margin: auto;">
            <b style="padding: .15rem .5rem; background: white; border: 2px solid var(--color-alternative); border-radius: 3px; text-shadow: none; color: black; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{r.code || r.nom || '????????'}}</b>
        </div>
      </router-link>
      <button type="button" @click="nouvelleRuche" style="overflow: hidden; display: inline-block; min-width: 120pt">
          <img src="/img/ic_ruche_ajout.svg" alt="" style="height: 80pt; width: 80pt; opacity: .4;">
          <div style="padding: .15rem .5rem; border: 2px solid transparent; opacity: .35;  white-space: nowrap;">Nouvelle ruche</div>
      </button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      ruches: []
    }
  },
  methods: {
    initRuches() {
      this.$DB['ruches'].allDocs({include_docs: true}).then((docs) => {
        this.ruches = docs.rows.filter(r => r.doc.rucher == this.$route.params.id).map(r => r.doc)
      })
    },
    nouvelleRuche() {
      const ruche = {
          rucher: this.$route.params.id,
          nom: "",
          code: "",
          colonie: {}
      }
      console.log('should create new entry: ', ruche)
      this.$DB['ruches'].post(ruche).then((r) => {
          console.log(r)
          this.initRuches()
          this.$emit('change')
          this.$router.push(`/ruchers/${this.$route.params.id}/${r.id}`)
      })
    },
  },
  mounted() {
    this.initRuches()
  }
}
</script>

<style lang="stylus" scoped>
#ruches
    display flex
    align-items center
    flex-wrap wrap
    button
    .button
      flex 1
      margin .25rem
      width 100pt
      min-width 100pt
      max-width 100pt
      padding .5rem .85rem!important
</style>