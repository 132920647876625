<template>
  <div>
      <form action="" style="padding: 2rem; margin: .5rem auto; max-width: 400pt">
          <img src="/img/illus_rucher.svg" alt="" :style="`height: 100pt; width: 100pt; border-radius: 50%; background-image: url('/img/illus_lieu_${rucher.environnement}.svg');`">

          <h2>Nouveau rucher</h2>

          <label>
              Code d'identification<br />
              <input type="text" v-model="rucher.nom" placeholder="Code d'identification du rucher">
          </label>
          <br />
          <label>
              Adresse<br />
              <input type="text" v-model="rucher.adresse" placeholder="N° et nom de la rue">
          </label>

          <div style="display: flex;">
            <label>
                CP<br />
                <input type="number" min="10000" max="99999" v-model="rucher.cp" placeholder="00000" style="width: 10ch">
            </label>
            &nbsp;
            <label style="flex: 1">
                Ville<br />
                <input type="text" v-model="rucher.ville" placeholder="Nom de la commune">
            </label>
          </div>
          <br />
          <label>
              Environnement<br />
              <select v-model="rucher.environnement">
                  <option value=""></option>
                  <option value="foret">Forêt</option>
                  <option value="montagne">Montagne</option>
                  <option value="fleurs">Fleurs</option>
              </select>
          </label>
          <br />
          <label>
              Date d'installation<br />
              <input type="date" v-model="rucher.installation" >
          </label>

          <button type="button" class="primary" @click="save">Ajouter</button>
      </form>
  </div>
</template>

<script>
export default {
    data() {
        return {
            rucher: {}
        }
    },
    methods: {
        save(e) {
            if(e) {
                e.target.setAttribute('disabled', true)
                setTimeout((t) => {
                    t.removeAttribute('disabled')
                }, 5000, e.target)
            }
            this.$DB['ruchers'].post(this.rucher).then((r) => {
                this.$emit('change')
                this.$router.push('/ruchers/'+ r.id)
            })
        }
    }
}
</script>

<style>

</style>