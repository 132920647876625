<template>
  <div class="etiquettes">
    <h1>Étiquettes</h1>
    <div id="etiquettes">
      <router-link
        v-for="(e, index) in etiquettes"
        :key="e._id"
        :to="{name: 'EtiquettePlanche', params: {idEtiquette: e._id}}"
        style="text-decoration: none; color: inherit;">
        <input type="checkbox" class="ckbx" @click.stop @change="() => { toggleSelection(e._id) }">
        <Etiquette :data="e" style="transform: scale(.8) rotate(-5deg); border-radius: .15rem; margin: -5% 0; box-shadow: 0 5px 10px rgba(0,0,0,.2)" />
        {{e.nom || 'Étiquette #'+(index+1)}}
      </router-link>

    </div>
    <div v-if="!ready"></div>
    <div id="actions" v-else-if="ruchers.length">
      <button v-if="selection.length" @click="bulkRemove">Supprimer</button>
      &nbsp;
      <router-link :to="{name :'EtiquetteEdition', params: {id: 'nouvelle'}}" button class="primary">Nouvelle étiquette</router-link>
    </div>
    <div v-else>
      <p>Il n'y a pas encore de données ici.</p>
      <img src="/img/illus_man_dunno.svg" alt="" style="height: 256px">
      <p>Pour pouvoir créer une étiquette, vous devez d'abord <router-link class="primary" to="/ruchers/nouveau">ajouter au moins un rucher</router-link>.</p>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
#etiquettes
  display flex
  flex-wrap wrap
  justify-content center
  margin 3rem auto
  align-content flex-start
  justify-content flex-start
  & > *
    padding .5rem
    margin .5rem
    transition .1s
    border-radius .5rem
    background rgba(0,0,0,.01)
    position relative
    .ckbx
      position absolute
      top .5rem
      left .5rem
      &:not(:checked)
        opacity 0
    &:hover
      background rgba(0,0,0,.07)
      .ckbx
        opacity 1
</style>

<script>
import Etiquette from './EtiquetteElement.vue'
export default {
  components: {Etiquette},
  data() {
    return {
      etiquettes: [],
      selection: [],
      ruchers: [],
      ready: false
    }
  },
  methods: {
    toggleSelection(id) {
      const existing = this.selection.findIndex(x => x == id)
      if(existing > -1) {
        this.selection.splice(existing, 1)
      } else {
        this.selection.push(id)
      }
      this.$forceUpdate()
    },
    bulkRemove() {
      this.selection.forEach(s => {
        this.$DB['etiquettes'].get(s).then(doc => {
          this.$DB['etiquettes'].remove(doc)
          this.selection.splice(this.selection.findIndex(e => e == s), 1)
          this.etiquettes.splice(this.etiquettes.findIndex(e => e._id == s), 1)
        })
      })
    },
    initEtiquettes() {
      return this.$DB['etiquettes'].allDocs({include_docs: true}).then((docs) => {
        this.etiquettes = docs.rows.map(r => r.doc)
      })
    },
    initRuchers() {
      return this.$DB['ruchers'].allDocs().then(async (docs) => {
        this.ruchers = docs.rows
      })
    }
  },
  mounted() {
    Promise.all([
      this.initEtiquettes(),
      this.initRuchers()
    ]).then(() => {
      this.ready = true
    })
  }
}
</script>