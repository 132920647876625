import { createApp } from 'vue'
import App from './App.vue'
import MobileApp from './MobileApp.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Helpers from './helpers'
import HelpIcon from './components/HelpIcon'
import PouchDB from 'pouchdb'

if(window.innerWidth < 640) {
    createApp(MobileApp)
        .mount('#app')
} else {

    const DB = {};
    Helpers.getDbList().forEach(dbname => {
        DB[dbname] = new PouchDB(dbname, { auto_compaction: true })
    })

    const app = createApp(App)
        .use(store)
        .use(router)
    app.component('HelpIcon', HelpIcon)
    app.config.globalProperties.$DB = DB
    app.config.globalProperties.$helpers = Helpers
    app.mount('#app')
}
