<template>
  <div class="home" style="flex: 1; display: flex; justify-content: center; align-items: center; flex-direction: column;">
    <h1>Bonjour {{userData.prenom}} !</h1>
    <p>Utilisez les menus en haut de page pour naviguer dans l'application !</p>
    <img alt="Vue logo" src="/img/illus_man_up.svg" style="height: 240pt; width: 240pt;">
    <!-- <h1>Api'Gestion</h1> -->
  </div>
</template>

<script>


export default {
  name: 'Home',
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('api_userdata') || '{}')
    }
  }
}
</script>
