<template>
    <div style="flex: 1; overflow: auto; padding: 1rem">
        <form>
            <h2>Vue d'ensemble</h2>
            <Top5RuchersNbRuches />
        </form>
  </div>
</template>

<script>
import Top5RuchersNbRuches from './Top/Top5RuchersNbRuches'
export default {
    components: {Top5RuchersNbRuches},
    data() {
        return {
            rucher: {}
        }
    }
}
</script>

<style>

</style>