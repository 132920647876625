export default class Helpers {

    static TYPE_ACACIA = "acacia"
    static TYPE_CHATAIGNER = "chataigner"
    static TYPE_FLEURS = "fleurs"
    static TYPE_FORET = "foret"
    static TYPE_MONTAGNE = "montagne"

    static afficheTypeMiel(type) {
        switch (type) {
            case this.TYPE_ACACIA:
                return "Miel d'Acacia";
            case this.TYPE_CHATAIGNER:
                return "Miel de Châtaigner";
            case this.TYPE_FLEURS:
                return "Miel de Fleurs";
            case this.TYPE_FORET:
                return "Miel de Forêt";
            case this.TYPE_MONTAGNE:
                return "Miel de Montagne";
            default:
                return "Miel d'Apiculteur";
        }
    }

    static illustrationTypeMiel(type) {
        switch (type) {
            case this.TYPE_ACACIA:
                return "url(/img/backgrounds/bg_acacia.jpg); background-size: cover;";
            case this.TYPE_CHATAIGNER:
                return "url(/img/backgrounds/bg_chataigner.jpg); background-size: cover;";
            case this.TYPE_FLEURS:
                return "url(/img/backgrounds/bg_fleurs.jpg); background-size: cover;";
            case this.TYPE_FORET:
                return "url(/img/backgrounds/bg_foret.jpg); background-size: cover;";
            case this.TYPE_MONTAGNE:
                return "url(/img/backgrounds/bg_montagne.jpg); background-size: cover;";
            case `${this.TYPE_ACACIA}_icon`:
                return "url(/img/backgrounds/abeille.png); background-position: top left; background-repeat: no-repeat; background-size: 25%";
            case `${this.TYPE_CHATAIGNER}_icon`:
                return "url(/img/backgrounds/ic_chataigner.png); background-position: top left; background-repeat: no-repeat; background-size: 25%";
            case `${this.TYPE_FLEURS}_icon`:
                return "url(/img/backgrounds/ic_fleurs.png); background-position: top left; background-repeat: no-repeat; background-size: 25%";
            case `${this.TYPE_FORET}_icon`:
                return "url(/img/backgrounds/abeille.png); background-position: top left; background-repeat: no-repeat; background-size: 25%";
            case `${this.TYPE_MONTAGNE}_icon`:
                return "url(/img/backgrounds/abeille.png); background-position: top left; background-repeat: no-repeat; background-size: 25%";
            case `_icon`:
            case `undefined_icon`:
                return "url(/img/backgrounds/ruche.png); background-position: top left; background-repeat: no-repeat; background-size: 25%";
            default:
                return "url(/img/motif_honeycomb.svg)";
        }
    }

    static couleurTypeMiel(type) {
        switch (type) {
            case this.TYPE_ACACIA:
                return "#bc0052";
            case this.TYPE_CHATAIGNER:
                return "#80571e";
            case this.TYPE_FLEURS:
                return "#c288bc";
            case this.TYPE_FORET:
                return "#2e6a2b";
            case this.TYPE_MONTAGNE:
                return "#6d8e9f";
            default:
                return "#d38e00";
        }
    }

    static downloadFile(filename, text, mime) {
        var element = document.createElement('a');
        element.setAttribute('href', `data:${mime || 'text/plain'};charset=utf-8,` + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    static readFile(file) {
        return new Promise((resolve) => {
            var reader = new FileReader()
            reader.onload = function () {
                resolve(reader.result)
            }
            reader.readAsText(file)
        })
    }

    static getDbList() {
        return ['ruchers', 'ruches', 'etiquettes']
    }
}