<template>
    <div style="flex: 1; align-items: center; justify-content: center; display: flex;">
        <div id="parametres">
            <div class="menu">
                <router-link :to="{name: 'ParametresInformations'}">Informations personnelles</router-link>
                <router-link :to="{name: 'ParametresSauvegarde'}">Sauvegarde et restauration</router-link>
            </div>
            <div class="childview">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="stylus">
#parametres
    flex 1
    display flex
    overflow hidden
    margin 1rem
    border-radius .75rem
    background white
    box-shadow 0 0 0 1px rgba(0,0,0,.1)
    padding 0!important
    height 80%
    min-height 600px
    .menu
        width 200pt
        background rgba(0,0,0,.05)
        text-align left
        overflow-x hidden
        z-index 2
        a
            display block
            padding .75rem
            text-decoration none
            color rgba(0,0,0,.6)
        a.router-link-active
            background white
            box-shadow 0 0 .5rem 1px rgba(0,0,0,.07)
            color var(--color-primary)
            text-shadow 0 1px 0 rgba(0,0,0,.2)
            
    .childview
        flex 1
        z-index 1
        box-shadow 0 0 .5rem 1px rgba(0,0,0,.05)
        overflow auto
</style>