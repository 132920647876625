<template>
  <div style="flex: 1; overflow: auto;">
      <form style="padding: 2rem; margin: .5rem auto; max-width: 520pt" v-if="!$route.params.ruche_id">
          <img src="/img/illus_rucher.svg" alt="" :style="`height: 100pt; width: 100pt; border-radius: 50%; background-image: url('/img/illus_lieu_${rucher.environnement}.svg');`">

        <div style="height: 3rem">
          <h2 style="margin-bottom: -.5rem;"  v-if="rucher.nom">{{rucher.nom}}</h2>
          <h2  style="margin-bottom: -.5rem;" v-else>Rucher {{rucher.adresse}}</h2>
          <small style="opacity: .66; text-transform: uppercase; font-size: .66rem;">{{rucher.cp}} {{rucher.ville}}</small>
        </div>

        <div v-if="isModif">
          <label>
              Code d'identification<br />
              <input type="text" v-model="rucher.nom" placeholder="Code d'identification du rucher">
          </label>
          <br />
          <label>
              Adresse<br />
              <input type="text" v-model="rucher.adresse" placeholder="N° et nom de la rue">
          </label>

          <div style="display: flex;">
            <label>
                CP<br />
                <input type="number" min="10000" max="99999" v-model="rucher.cp" placeholder="00000" style="width: 10ch">
            </label>
            &nbsp;
            <label style="flex: 1">
                Ville<br />
                <input type="text" v-model="rucher.ville" placeholder="Nom de la commune">
            </label>
          </div>
          <br />
          <label>
              Environnement<br />
              <select v-model="rucher.environnement">
                  <option value=""></option>
                  <option value="foret">Forêt</option>
                  <option value="montagne">Montagne</option>
                  <option value="fleurs">Fleurs</option>
              </select>
          </label>
          <br />
          <label>
              Date d'installation<br />
              <input type="date" v-model="rucher.installation" >
          </label>

          <button type="button" class="primary" @click="save">Mettre à jour</button>
          &nbsp;
          <button type="button" @click="remove">Supprimer</button>
        </div>
        <div v-else style="margin-top: 1rem;">
          <span style="color: white; text-shadow: .5px .5px 1.5px var(--color-alternative); padding: 0 .75rem 0 0; border-radius: .3rem; background: var(--color-primary-dark); display: inline-flex; align-items: center; overflow: hidden; line-height: 1.75rem; box-shadow: 0 1px 3px 1px rgba(0,0,0,.1);">
                <span style="background: var(--color-alternative); padding: 0 .3rem 0 .4rem; margin-right: .3rem; min-height: 1rem; min-width: 1rem; display: inline-block;">{{ruches.length}}</span>
                ruche{{ruches.length > 1 ? 's' : ''}}
            </span> 
          &nbsp;
          <button type="button" @click="() => { isModif = true }">Modifier</button>
        </div>
      </form>
    <router-view @change="$emit('change')"/>
  </div>
</template>

<script>
export default {
    data() {
        return {
            isModif: false,
            rucher: {},
            ruches: []
        }
    },
    methods: {
        save(e) {
            if(e) {
                e.target.setAttribute('disabled', true)
                setTimeout((t) => {
                    t.removeAttribute('disabled')
                }, 5000, e.target)
            }
            this.$DB['ruchers'].put(this.rucher).then((r) => {
                this.rucher._rev = r.rev
                this.isModif = false
                this.$emit('change')
            }).catch((e) => {
                alert(e.message)
            })
        },
        remove() {
            if(confirm('Supprimer ce rucher et toutes ses ruches ?')) {
                this.$DB['ruchers'].get(this.rucher._id).then((doc) => {
                    this.ruches.forEach((rucheDoc) => {
                        return this.$DB['ruchers'].remove(rucheDoc)
                    })
                    return this.$DB['ruchers'].remove(doc).then(() => {
                        this.$emit('change')
                        this.$router.push(`/ruchers`)
                    })
                })
            }
        },
        initRuches() {
            this.$DB['ruches'].allDocs({include_docs: true}).then((docs) => {
                this.ruches = docs.rows.filter(r => r.doc.rucher == this.rucher._id).map(r => r.doc)
            })
        }
    },
    mounted() {
        this.$DB['ruchers'].get(this.$route.params.id).then((doc) => {
            this.rucher = doc
            this.initRuches()
        })
    }
}
</script>
