<template>
  <div id="setup">
      <div id="container">
        <form action="" v-if="!step" @submit.prevent="()=> { step++ }">

            <h2>Bienvenue dans votre logiciel de gestion !</h2>
            <p>C'est la première fois ? Pas de panique !</p>
            <p>La configuration de votre logiciel ne prendra que quelques instants, il vous suffit de répondre aux quelques questions qui suivent.</p>
            <div style="display: flex; justify-content: center; margin-top: 1rem;">
                <button class="primary">C'est parti !</button>
            </div>
        </form>
        
        <form action="" v-if="step == 1" @submit.prevent="()=> { step++ }">

            <h2>Qui êtes-vous ?</h2>
            <div style="display: flex; justify-content: space-between;">
                <label>Votre nom<br/>
                    <input required type="text" placeholder="Votre nom" v-model="userData.nom">
                </label>
                <label>Votre prénom<br/>
                    <input required type="text" placeholder="Votre prénom" v-model="userData.prenom">
                </label>
            </div>
            
            <label>Votre adresse<br/>
                <input required type="text" placeholder="N° et nom de rue" v-model="userData.adresse1" style="margin-bottom: 4px">
                <input type="text" placeholder="Infos supplémentaires" v-model="userData.adresse2" style="margin-bottom: 4px">
                <input required type="text" placeholder="CP et Ville" v-model="userData.adresse3">
            </label>

            <label>Votre N° de téléphone<br/>
                <input type="text" placeholder="Votre téléphone" v-model="userData.telephone">
            </label>

            <label>Votre N° d'apiculteur (NAPI)<br/>
                <input type="text" placeholder="XX-XXXXX" v-model="userData.napi">
                <a style="display: block; text-align: right;" href="https://agriculture-portail.6tzen.fr/default/requests/Cerfa13995/">Obtenir un numéro NAPI</a>
            </label>

            <label>Votre N° SIRET<br/>
                <input type="text" placeholder="(Laissez vide si vous n'avez pas de SIRET)" v-model="userData.siret">
                <a style="display: block; text-align: right;" href="https://www.autoentrepreneur.urssaf.fr/">Devenir auto-entrepreneur</a>
            </label>
            <div style="display: flex; justify-content: center; margin-top: 1rem;">
                <!-- <button class="small" @click="()=> { step-- }">Précédent</button> -->
                <button class="primary">Suivant</button>
            </div>
        </form>

        <form action="" v-if="step == 2">

            <h2>C'est prêt, {{userData.prenom}} !</h2>
            <p>Tout est en ordre, vous allez pouvoir commencer à utiliser votre logiciel !</p>
            <p>Une dernière chose... Quand vous verrez le pictogramme ci-dessous, vous pouvez cliquer dessus pour que je vienne vous aider :</p>
            <HelpIcon @toggle="() => { helpCheck = true }">
                <template v-slot:help>
                    <h3>Félicitations !</h3>
                    <p>Vous savez tout, il ne vous reste qu'à fermer cette bulle d'aide en cliquant sur la croix (&nbsp;&times;&nbsp;), puis sur le bouton <b>Terminé</b> !</p>
                </template>
            </HelpIcon>
            <p>Essayez, pour voir...</p>
            <div style="display: flex; justify-content: center; margin-top: 1rem;">
                <button type="button" class="small" @click="()=> { step-- }">Modifier mes informations</button>
                <button :disabled="!helpCheck" class="primary" @click="()=> { terminate() }">Terminé</button>
            </div>
        </form>

        <form action="" v-if="step == 3">

            <h2>C'est prêt !</h2>
            <p>Merci {{userData.prenom}} !</p>
            <p>Tout est en ordre, vous allez pouvoir commencer à utiliser votre logiciel !</p>
            <div style="display: flex; justify-content: center; margin-top: 1rem;">
                <button class="small" @click="()=> { step-- }">J'ai fait une erreur</button>
                <button class="primary" @click="()=> { terminate() }">Terminé !</button>
            </div>
        </form>
      </div>
      <img src="/img/illus_man.svg" alt="" id="man">
  </div>
</template>

<script>
export default {
    data() {
        return {
            helpCheck: false,
            userData: JSON.parse(localStorage.getItem('api_userdata') || '{}'),
            step: 0
        }
    },
    methods: {
        terminate() {
            localStorage.setItem('api_setupdate', new Date().toISOString())
            this.$router.replace('/')
        }
    },
    mounted() {
        if(localStorage.getItem('api_setupdate')) {
            this.$router.replace('/')
        }
    },
    watch: {
        userData: {
            handler: function(nextValue) {
                localStorage.setItem('api_userdata', JSON.stringify(nextValue))
            },
            deep: true
        },
        step: function() {
            setTimeout(() => {
                try {
                    document.querySelector('input[type="text"]').focus()
                } catch(e) {
                    //
                }
            }, 200)
        }
    }
}
</script>

<style lang="stylus" scoped>
#setup
    position fixed
    top 0
    left 0
    height 100vh
    width 100vw
    box-sizing border-box
    background var(--color-primary) url('/img/motif_honeycomb.svg') center center
    background-size 100px
    z-index 3
    overflow hidden
    display flex
    flex-direction column
    padding 0 1rem
img#appLogo
    height 128px
    width 128px
img#man
    min-height 240pt
    height 240pt
    width 240pt
    margin 0 auto
#container
    flex 1
    display flex
    align-items flex-end
form
    max-width 400pt
    width calc(100vh - 2rem)
    margin auto
    padding 2rem
    border-radius 1rem
    display flex
    flex-direction column
    align-items center
    margin-top 1rem
    margin-bottom 1rem
    position relative
    animation man-enter ease-out 1s
    &::after
        content ''
        display block
        height 2rem
        width 2rem
        background white
        position absolute
        bottom -1rem
        transform rotate(45deg)
        z-index 0
        box-shadow .33rem .33rem .33rem rgba(0,0,0,.15)
        right calc(50% - 1rem)
    &>*
        width 100%
        max-width 250pt
        box-sizing border-box
    button
        cursor pointer
        text-decoration none
        margin .25rem
        padding .5rem .75rem
        border-radius .5rem
    button.small
        color rgba(0,0,0,.5)
        border-color transparent
    button.primary
        color white
        border 1pt solid var(--color-primary-dark)
        text-shadow 0 0.5px 2px var(--color-alternative)
        background var(--color-primary)
        background linear-gradient(to bottom, var(--color-primary), var(--color-primary-dark))
        position relative
        box-shadow 0 2pt 5pt rgba(0,0,0 .2), inset 0 -1pt 3pt rgba(0,0,0 .15)
        &::before
            content ''
            display block
            height .75rem
            position absolute
            top 1pt
            left 1pt
            right 1pt
            border-radius .35rem
            background linear-gradient(to bottom, rgba(255,255,255,.75), rgba(255,255,255,.25))
@keyframes man-enter
    from
        transform scale(1) translateY(30%)
        opacity 0
    50%
        transform scale(.9, 1.2) translateY(0)
        opacity 1
    75%
        transform scale(1.1, .9)
    to
        transform scale(1)
</style>