<template>
  <div style="overflow: auto">
    <h1>Imprimer une planche</h1>
    <div class="form">
      <div class="main">
        <label style="margin: 0">
          Nombre d'étiquettes :&nbsp;
        </label>
        <input type="number" v-model="nb" max="99" min="1" step="1" style="text-align: right">
        <div style="flex: 1"></div>
        <button @click="() => { advanced = !advanced }">Paramétrage</button>
        &nbsp;
        <button class="primary" @click="print">Imprimer</button>
      </div>
      <div class="advanced" v-if="advanced">
        <fieldset>
          <legend>Modifier les informations</legend>
          <p style="text-align: justify; padding: .5rem; font-size: .8em; border-radius: .5rem; background: rgba(0,0,0,.033); font-style: italic; color: rgba(0,0,0,.5)">
            Ici, vous pouvez modifier certaines informations présentes sur l'étiquette. Attention, ces modifications ne s'appliquent que pour cette impression, pour modifier durablement le modèle d'étiquettes, accédez à l'<router-link :to="{name :'EtiquetteEdition', params: {id: etiquette._id}}" >édition du modèle d'étiquette</router-link>.
          </p>

          <label style="margin: 0">
            Quantité nette :&nbsp;
            <input type="number" v-model="etiquette.poidsNet" max="9999" min="1" step="1" style="text-align: right" @change="lastChange = Date.now()">
            <small>(en grammes)</small>
          </label>
          <br/>
          <label>
            Date de Durabilité Minimale (DDM) :
            <select v-model="etiquette.typeDdm" @change="lastChange = Date.now()">
              <option value="calcul">
                Sur l'étiquette (calcul automatique)
              </option>
              <option value="couvercle">
                Sur le couvercle (à ajouter manuellement)
              </option>
              <option value="dessous">
                Sous le pot (à ajouter manuellement)
              </option>
            </select>
            <div>
              <input
                :disabled="etiquette.typeDdm != 'calcul'"
                type="number"
                v-model="etiquette.moisDdm"
                min="0"
                style="
                  width: 6ch;
                  margin-left: -0.25rem;
                  margin-right: 0.25rem;
                "
              />
              <small
                >(Nombre de mois à compter de la date d'impression)</small
              >
            </div>
          </label>
        </fieldset>
        
        <br>
        <small>
          <router-link :to="{name :'EtiquetteEdition', params: {id: etiquette._id}}" >Modifier le modèle d'étiquette ?</router-link>
        </small>
      </div>
    </div>
    <div class="page" v-if="etiquette">
      <div v-for="i of parseInt(nb, 10)" :key="i" style="display: flex; position: relative;">
        <Etiquette :data="etiquette" :key="lastChange"/>
      </div>
    </div>
  </div>
</template>

<script>
import Etiquette from './EtiquetteElement.vue'
export default {
  components: {Etiquette},
  data() {
    return {
      lastChange: 0,
      advanced: false,
      nb: 4,
      etiquette: null
    }
  },
  methods: {
    loadEtiquette(id) {
      this.$DB["etiquettes"].get(id).then((doc) => {
        this.etiquette = doc
        this.poidsNet = doc.poidsNet
      })
    },
    print() {
      window.print()
    }
  },
  mounted() {
    if(this.$route.params.idEtiquette) {
      console.log(this.$route.params.idEtiquette)
      //charger l'étiquette
      this.loadEtiquette(this.$route.params.idEtiquette)
    } else {
      //huh ?
    }
  }

}
</script>

<style lang="stylus" scoped>
.form
  display flex
  flex-direction column
  width 500pt
  margin 1rem auto
  background white
  border-radius .5rem
  box-shadow 0 5px 12px 1px rgba(0,0,0,.15)
  .main
    display flex
    align-items center
    padding .5rem
  .advanced
    padding .5rem

.page
  display flex
  flex-wrap wrap
  justify-content flex-start
  align-items flex-start
  align-content flex-start
  margin 2rem auto
  box-shadow 0 5px 15px rgba(0,0,0,.2)
  background white
  width 21cm
  height 29.7cm
  padding 1cm
  box-sizing border-box
  position relative
  zoom .5
  &::after
    content 'Aperçu'
    text-transform uppercase
    position absolute
    top 50%
    left 50%
    font-size 2cm
    opacity .15
    transform translate(-50%, -50%)

@media print
  .page
    margin 0
    padding 0
    width auto
    position fixed
    top 0
    left 0
    right 0
    z-index 9999
    box-shadow none!important
    zoom 1!important
    &::after
      display none
</style>