import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Ruchers from '../views/Ruchers.vue'
import ListeRuches from '../views/Ruchers/Ruches/Liste.vue'
import DetailsRuche from '../views/Ruchers/Ruches/Details.vue'
import NouveauRucher from '../views/Ruchers/Nouveau.vue'
import DetailsRucher from '../views/Ruchers/Details.vue'
import AccueilRuchers from '../views/Ruchers/Accueil.vue'
import Infos from '../views/Infos.vue'
import Parametres from '../views/Parametres.vue'
import ParametresBackup from '../views/Parametres/Backup.vue'
import Setup from '../views/Setup.vue'
import Etiquettes from '../views/Etiquettes.vue'
import EtiquettesAccueil from '../views/Etiquettes/Accueil.vue'
import EtiquetteEdition from '../views/Etiquettes/Edition.vue'
import EtiquettePlanche from '../views/Etiquettes/Planche.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/ruchers',
    component: Ruchers,
    children: [
      {
        path: 'nouveau',
        name: 'NouveauRucher',
        component: NouveauRucher
      },
      {
        path: 'accueil',
        name: 'AccueilRuchers',
        component: AccueilRuchers
      },
      {
        path: ':id',
        component: DetailsRucher,
        children: [
          {
            path: ':ruche_id',
            name: 'DetailsRuche',
            component: DetailsRuche
          },
          {
            path: '',
            component: ListeRuches
          }
        ]
      },
      {
        path: '',
        redirect: {name: 'AccueilRuchers'}
      }
    ]
  },
  {
    path: '/parametres',
    name: 'Parametres',
    component: Parametres,
    children: [
      {
        path: 'infos',
        name: 'ParametresInformations',
        component: Infos
      },
      {
        path: 'backup',
        name: 'ParametresSauvegarde',
        component: ParametresBackup
      },
      {
        path: '',
        redirect: {name: 'ParametresInformations'}
      }
    ]
  },
  {
    path: '/etiquettes',
    name: 'Etiquettes',
    component: Etiquettes,
    children: [
      {
        path: '/etiquettes/:id',
        name: 'EtiquetteEdition',
        component: EtiquetteEdition
      },
      {
        path: '/etiquettes/:idEtiquette/planche',
        name: 'EtiquettePlanche',
        component: EtiquettePlanche
      },
      {
        path: '',
        name: 'EtiquetteAccueil',
        component: EtiquettesAccueil
      }
    ]
  },
  {
    path: '/setup',
    name: 'Setup',
    component: Setup
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
